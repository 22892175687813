<template>
  <div class="topNavBar">
    <img src="@/assets/images/logo.png" alt="" class="logo" />
    <img
      src="@/assets/images/menu.png"
      alt=""
      class="menu"
      @click="isMenu = true"
    />
    <el-dialog
      :modal="false"
      :visible.sync="isMenu"
      :fullscreen="true"
      class="menu_box"
    >
      <el-menu
        :default-active="activeIndex"
        mode="vertical"
        text-color="#333333"
        active-text-color="#006eff"
        :router="true"
        class="box"
      >
        <template v-for="item in navList">
          <el-submenu
            v-if="item.children"
            :popper-append-to-body="false"
            :collapse-transition="false"
            :key="item.key"
            :index="item.path"
          >
            <template slot="title">{{ item.title }}</template>
            <el-menu-item
              :index="item2.path"
              v-for="item2 in item.children"
              :key="item2.path"
              >{{ item2.title }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item v-else :index="item.path" :key="item.key">{{
            item.title
          }}</el-menu-item>
        </template>
      </el-menu>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "/mobile",
      navList: [
        {
          title: "首页",
          path: "/mobile",
        },
        {
          title: "产品及解决方案",
          path: "/mobile/product",
          children: [
            { title: "智慧文博场馆", path: "/mobile/product/venue" },
            { title: "AR/VR数字展厅", path: "/mobile/product/exhibitionHall" },
            { title: "智慧政务", path: "/mobile/product/affairs" },
            { title: "数智集团", path: "/mobile/product/group" },
            { title: "智慧医疗", path: "/mobile/product/health" },
          ],
        },
        {
          title: "大数据应用",
          path: "/mobile/bigData",
        },
        {
          title: "招聘",
          path: "/mobile/recruit",
        },
        {
          title: "新闻动态",
          path: "/mobile/journalism",
        },
        {
          title: "关于我们",
          path: "/mobile/aboutUs",
        },
      ],
      isMenu: false,
    };
  },
  mounted() {},
  methods: {},
  watch: {
    "$route.path": {
      immediate: true,
      handler(value) {
        this.activeIndex = value;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.topNavBar {
  background-color: #ffffff;
  position: sticky;
  height: 55px;
  top: 0;
  z-index: 100;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  .logo {
    margin-left: 12px;
    width: 155px;
    height: 19px;
  }
  .menu {
    margin-right: 12px;
    width: 25px;
    height: 25px;
  }
  ::v-deep .menu_box {
    .el-dialog__header {
      padding: 0;
      height: 40px;
      border: 1px solid #e4e4e4;
      .el-dialog__headerbtn {
        top: 12px;
        .el-dialog__close {
          font-size: 20px;
        }
      }
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-menu {
      .el-menu-item {
        font-size: 15px;
        line-height: 60px;
        height: 60px;
        font-weight: 400;
        text-align: left;
        border-bottom: 1px solid #eeeeee;
      }
      .el-submenu {
        .el-submenu__title {
          line-height: 60px;
          height: 60px;
          font-size: 15px;
          border-bottom: none;
          font-weight: 400;
          text-align: left;
          border-bottom: 1px solid #eeeeee;
        }
        .el-submenu__icon-arrow {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
