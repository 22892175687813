import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import TopNavBar from "@/components/TopNavBar.vue";
Vue.component("TopNavBar", TopNavBar);
import TopNavBar2 from "@/components/mobile/TopNavBar.vue";
Vue.component("TopNavBar2", TopNavBar2);
import Footer from "@/components/Footer.vue";
Vue.component("Footer", Footer);
import Footer2 from "@/components/mobile/Footer.vue";
Vue.component("Footer2", Footer2);
import Carousel from "@/components/Carousel.vue";
Vue.component("Carousel", Carousel);
import Title from "@/components/Title.vue";
Vue.component("Title", Title);
Vue.use(ElementUI);
import "@/assets/js/resize.js";
router.afterEach(() => {
  window, scrollTo(0, 0);
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
