<template>
  <div class="footer">
    <div class="left">
      <h2>产品及解决方案</h2>
      <div class="navBox">
        <div v-for="(item, index) in list" :key="index" class="item">
          <span
            @click="toPath(item.path)"
            :style="{ color: $route.path === item.path ? '#006EFF' : '' }"
            >{{ item.title }}</span
          >
        </div>
      </div>
    </div>
    <div class="right">
      <div class="navBox">
        <h2 @click="toPath('/mobile/bigData')" class="left">大数据应用</h2>
        <div></div>
        <h2 @click="toPath('/mobile/recruit')" class="right">招聘</h2>
      </div>
      <div class="info">
        <div>
          <img src="@/assets/images/icon_hotline.png" alt="" />
          <span>客服热线：0576-81810718</span>
        </div>
        <div>
          <img src="@/assets/images/icon_time.png" alt="" />
          <span>（周一至周五 9:00-15:00）</span>
        </div>
        <div>
          <img src="@/assets/images/icon_address.png" alt="" />
          <span>公司地址：浙江省台州市椒江区第一时间生活广场2楼255室</span>
        </div>
      </div>
    </div>
    <div class="copyright">
      Copyright 2022-2032 浙江起航软件科技有限公司 版权所有
      <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备12003971号</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { title: "智慧文博场馆", path: "/mobile/product/venue" },
        { title: "智慧政务", path: "/mobile/product/affairs" },
        { title: "AR/VR数字展厅", path: "/mobile/product/exhibitionHall" },
        { title: "数智集团", path: "/mobile/product/group" },
        { title: "智慧医疗", path: "/mobile/product/health" },
      ],
    };
  },
  methods: {
    toPath(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  border-top: 2px solid #eeeeee;
  padding: 20px 15px 25px;
  background-color: #f6f8f9;
  .left {
    h2 {
      text-align: left;
      font-size: 15px;
      font-family: "AlibabaPuHuiTi-Regular";
      font-weight: 400;
      color: #000000;
      line-height: 40px;
    }
    .navBox {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      .item {
        width: 50%;
        font-size: 14px;
        line-height: 40px;
        span {
          font-family: "AlibabaPuHuiTi-Light";
          color: #000000;
          font-weight: 200;
        }
      }
      .item:nth-child(2),
      .item:nth-child(4) {
        span {
          margin-left: 20px;
        }
      }
    }
  }
  .right {
    .navBox {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      > h2 {
        text-align: left;
        font-size: 15px;
        font-family: "AlibabaPuHuiTi-Regular";
        font-weight: 400;
        color: #000000;
        line-height: 40px;
      }
      > div {
        height: 17px;
        width: 1px;
        background-color: #797979;
        margin: 0 60px 0 55px;
      }
    }
    .info {
      > div {
        font-size: 14px;
        padding: 5px 0;
        display: flex;
        > img {
          width: 14px;
          height: 14px;
          margin-top: 5px;
        }
        > span {
          margin-left: 5px;
          font-weight: 300;
          font-family: "AlibabaPuHuiTi-Light";
          color: #000000;
          text-align: left;
          line-height: 25px;
        }
      }
    }
  }
  .copyright {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    font-size: 11px;
    color: #000;
    font-weight: 200;
    font-family: "AlibabaPuHuiTi-Light";
    a {
      color: #000;
      margin-left: 10px;
      text-decoration: none;
    }
  }
}
</style>
