<template>
  <el-carousel
    arrow="never"
    :indicator-position="carousels.length <= 1 ? 'none' : ''"
  >
    <el-carousel-item v-for="(item, index) in carousels" :key="index">
      <div
        class="img"
        :style="{ backgroundImage: 'url(' + item.imgUrl + ')' }"
      ></div>
      <slot name="button"></slot>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    carousels: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.el-carousel ::v-deep {
  .el-carousel__container {
    height: calc(100vw * (1010 / 1920));
    .el-carousel__item {
      height: calc(100vw * (1010 / 1920));
      .img {
        height: calc(100vw * (1010 / 1920));
        background-size: cover;
      }
    }
  }
  .el-carousel__indicators {
    .el-carousel__indicator {
      .el-carousel__button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    .el-carousel__indicator + .el-carousel__indicator {
      margin-left: 15px;
    }
  }
  .el-carousel__indicators--horizontal {
    bottom: 12%;
  }
}
@media (max-width: 768px) {
  .el-carousel ::v-deep {
    // .el-carousel__container {
    //   width: 375px;
    //   height: 200px;
    //   .el-carousel__item {
    //     width: 375px;
    //     height: 200px;
    //     .img {
    //       width: 375px;
    //       height: 200px;
    //       background-size: cover;
    //     }
    //   }
    // }
    .el-carousel__indicators {
      .el-carousel__indicator {
        .el-carousel__button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      .el-carousel__indicator + .el-carousel__indicator {
        margin-left: 5px;
      }
    }
    .el-carousel__indicators--horizontal {
      bottom: 6px;
    }
  }
}
</style>
