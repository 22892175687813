<template>
  <div class="title">
    <img src="@/assets/images/u111.svg" alt="" class="top_left" />
    <img src="@/assets/images/u112.svg" alt="" class="top_left" />
    <img src="@/assets/images/u114.svg" alt="" class="right_bottom" />
    <img src="@/assets/images/u115.svg" alt="" class="right_bottom" />
    {{ title }}
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  display: inline;
  position: relative;
  padding: 16px 35px;
  font-size: 45px;
  color: #000;
  letter-spacing: 5px;
  > img {
    position: absolute;
  }
  .top_left {
    top: 0;
    left: 0;
  }
  .right_bottom {
    bottom: 0;
    right: 0;
  }
}
@media (max-width: 768px) {
  .title {
    display: inline;
    position: relative;
    padding: 8px 18px;
    font-size: 18px;
    color: #000;
    letter-spacing: 2px;
    > img {
      position: absolute;
    }
    .top_left {
      top: 0;
      left: 0;
    }
    .right_bottom {
      bottom: 0;
      right: 0;
    }
  }
}
</style>
