<template>
  <div class="topNavBar">
    <img src="@/assets/images/logo.png" alt="" />
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      text-color="#333333"
      active-text-color="#006eff"
      :router="true"
    >
      <template v-for="item in navList">
        <el-submenu
          v-if="item.children"
          :popper-append-to-body="false"
          :collapse-transition="false"
          :key="item.key"
          :index="item.path"
        >
          <template slot="title">{{ item.title }}</template>
          <el-menu-item
            :index="item2.path"
            v-for="item2 in item.children"
            :key="item2.path"
            >{{ item2.title }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item v-else :index="item.path" :key="item.key">{{
          item.title
        }}</el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "/",
      navList: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "产品及解决方案",
          path: "/product",
          children: [
            { title: "智慧文博场馆", path: "/product/venue" },
            { title: "AR/VR数字展厅", path: "/product/exhibitionHall" },
            { title: "智慧政务", path: "/product/affairs" },
            { title: "数智集团", path: "/product/group" },
            { title: "智慧医疗", path: "/product/health" },
          ],
        },
        {
          title: "大数据应用",
          path: "/bigData",
        },
        {
          title: "招聘",
          path: "/recruit",
        },
        {
          title: "新闻动态",
          path: "/journalism",
        },
        {
          title: "关于我们",
          path: "/aboutUs",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  watch: {
    "$route.path": {
      immediate: true,
      handler(value) {
        this.activeIndex = value;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.topNavBar {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  > img {
    margin-left: 30px;
    width: 310px;
    height: 38px;
  }
  ::v-deep .el-menu {
    margin-right: 160px;
    border-bottom: none;
    .el-menu-item,
    .el-submenu .el-submenu__title {
      padding: 0 53px;
      font-size: 25px;
      border-bottom: none !important;
      line-height: 70px;
      height: 70px;
      letter-spacing: 2px;
      font-weight: 400;
    }
    .el-submenu {
      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
}
@media (max-width: 1050px) {
  .topNavBar {
    ::v-deep .el-menu {
      margin-right: 160px;
      border-bottom: none;
      .el-menu-item,
      .el-submenu .el-submenu__title {
        padding: 0 20px;
        font-size: 20px;
        border-bottom: none !important;
        line-height: 50px;
        height: 50px;
        letter-spacing: 2px;
        font-weight: 400;
      }
      .el-submenu {
        .el-submenu__icon-arrow {
          display: none;
        }
      }
    }
  }
}
</style>
