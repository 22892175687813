import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  // 大数据应用
  {
    path: "/bigData",
    name: "BigData",
    component: () => import("@/views/BigData.vue"),
  },
  // 招聘
  {
    path: "/recruit",
    name: "Recruit",
    component: () => import("@/views/Recruit.vue"),
  },
  // 新闻动态
  {
    path: "/journalism",
    name: "Journalism",
    component: () => import("@/views/Journalism.vue"),
  },
  // 关于我们
  {
    path: "/aboutUs",
    name: "AboutUs",
    component: () => import("@/views/AboutUs.vue"),
  },
  //智慧文博场馆
  {
    path: "/product/venue",
    name: "Venue",
    component: () => import("@/views/product/Venue.vue"),
  },
  //AR/VR数字展厅
  {
    path: "/product/exhibitionHall",
    name: "ExhibitionHall",
    component: () => import("@/views/product/ExhibitionHall.vue"),
  },
  //智慧政务
  {
    path: "/product/affairs",
    name: "Affairs",
    component: () => import("@/views/product/Affairs.vue"),
  },
  //数智集团
  {
    path: "/product/group",
    name: "Group",
    component: () => import("@/views/product/Group.vue"),
  },
  //智慧医疗
  {
    path: "/product/health",
    name: "Health",
    component: () => import("@/views/product/Health.vue"),
  },
  //招聘详情
  {
    path: "/recruitDetails",
    name: "RecruitDetails",
    component: () => import("@/views/details/RecruitDetails.vue"),
  },
  //新闻动态详情
  {
    path: "/journalismDetails",
    name: "JournalismDetails",
    component: () => import("@/views/details/JournalismDetails.vue"),
  },
  // 首页
  {
    path: "/mobile",
    name: "Home2",
    component: () => import("@/views/mobile/Home.vue"),
  },
  // 大数据应用
  {
    path: "/mobile/bigData",
    name: "BigData2",
    component: () => import("@/views/mobile/BigData.vue"),
  },
  // 招聘
  {
    path: "/mobile/recruit",
    name: "Recruit2",
    component: () => import("@/views/mobile/Recruit.vue"),
  },
  // 新闻动态
  {
    path: "/mobile/journalism",
    name: "Journalism2",
    component: () => import("@/views/mobile/Journalism.vue"),
  },
  // 关于我们
  {
    path: "/mobile/aboutUs",
    name: "AboutUs2",
    component: () => import("@/views/mobile/AboutUs.vue"),
  },
  //智慧文博场馆
  {
    path: "/mobile/product/venue",
    name: "Venue2",
    component: () => import("@/views/mobile/product/Venue.vue"),
  },
  //AR/VR数字展厅
  {
    path: "/mobile/product/exhibitionHall",
    name: "ExhibitionHall2",
    component: () => import("@/views/mobile/product/ExhibitionHall.vue"),
  },
  //智慧政务
  {
    path: "/mobile/product/affairs",
    name: "Affairs2",
    component: () => import("@/views/mobile/product/Affairs.vue"),
  },
  //数智集团
  {
    path: "/mobile/product/group",
    name: "Group2",
    component: () => import("@/views/mobile/product/Group.vue"),
  },
  //智慧医疗
  {
    path: "/mobile/product/health",
    name: "Health2",
    component: () => import("@/views/mobile/product/Health.vue"),
  },
  //招聘详情
  {
    path: "/mobile/recruitDetails",
    name: "RecruitDetails2",
    component: () => import("@/views/mobile/details/RecruitDetails.vue"),
  },
  //新闻动态详情
  {
    path: "/mobile/journalismDetails",
    name: "JournalismDetails2",
    component: () => import("@/views/mobile/details/JournalismDetails.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
