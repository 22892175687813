<template>
  <div class="footer">
    <div class="box">
      <div class="left">
        <h2>产品及解决方案</h2>
        <div class="navBox">
          <div v-for="(item, index) in list" :key="index">
            <span @click="toPath(item.path)">{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="navBox">
          <h2 @click="toPath('/bigData')" class="left">大数据应用</h2>
          <div></div>
          <h2 @click="toPath('/recruit')" class="right">招聘</h2>
        </div>
        <div class="info">
          <div>
            <img src="@/assets/images/icon_hotline.png" alt="" />
            <span>客服热线：0576-81810718</span>
          </div>
          <div>
            <img src="@/assets/images/icon_time.png" alt="" />
            <span>（周一至周五 9:00-15:00）</span>
          </div>
          <div>
            <img src="@/assets/images/icon_address.png" alt="" />
            <span>公司地址：浙江省台州市椒江区第一时间生活广场2楼255室</span>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      Copyright 2022-2032 浙江起航软件科技有限公司 版权所有
      <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备12003971号</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { title: "智慧文博场馆", path: "/product/venue" },
        { title: "智慧政务", path: "/product/affairs" },
        { title: "AR/VR数字展厅", path: "/product/exhibitionHall" },
        { title: "数智集团", path: "/product/group" },
        { title: "智慧医疗", path: "/product/health" },
      ],
    };
  },
  methods: {
    toPath(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #eeeeee;
  padding: 45px 300px 50px;
  background-color: #f6f8f9;
  .box {
    display: flex;
    justify-content: space-between;
    .left {
      h2 {
        text-align: left;
        font-size: 25px;
        font-family: "AlibabaPuHuiTi-Regular";
        font-weight: 400;
        color: #000000;
        margin-bottom: 20px;
      }
      .navBox {
        width: 600px;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        > div {
          width: 300px;
          font-size: 20px;
          padding: 10px 0;
          span {
            font-family: "AlibabaPuHuiTi-Light";
            color: #000000;
            font-weight: 200;
            cursor: pointer;
          }
        }
      }
    }
    .right {
      .navBox {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        > h2 {
          font-size: 25px;
          font-family: "AlibabaPuHuiTi-Regular";
          font-weight: 400;
          color: #000000;
          cursor: pointer;
        }
        .left {
          width: 245px;
          text-align: left;
        }
        .right {
          width: 150px;
          text-align: right;
        }
        > div {
          height: 25px;
          width: 1px;
          background-color: #797979;
        }
      }
      .info {
        > div {
          font-size: 20px;
          padding: 10px 0;
          display: flex;
          > img {
            width: 20px;
            height: 20px;
            margin-top: 3px;
          }
          > span {
            margin-left: 5px;
            font-weight: 300;
            font-family: "AlibabaPuHuiTi-Light";
            color: #000000;
            text-align: left;
          }
        }
      }
    }
  }
  .copyright {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-size: 15px;
    color: #000;
    font-weight: 200;
    font-family: "AlibabaPuHuiTi-Light";
    letter-spacing: 0.5px;
    a {
      color: #000;
      margin-left: 10px;
      text-decoration: none;
    }
  }
}
</style>
